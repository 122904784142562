(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

// ----------- Native javascript ----------- //
// legacy browser alert
var ua = navigator.userAgent;

if (ua.indexOf("MSIE 6.0") != -1) {
  alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
} else if (ua.indexOf("MSIE 7.0") != -1) {
  alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
} else if (ua.indexOf("MSIE 8.0") != -1) {
  alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
} else if (ua.indexOf("MSIE 9.0") != -1) {
  alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
} else if (ua.indexOf("MSIE 10.0") != -1) {
  alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
} else {} // アニメーション


var EffectH = 100;
$(window).on('scroll load', function () {
  var scTop = $(this).scrollTop();
  var scBottom = scTop + $(this).height();
  var effectPos = scBottom - EffectH;
  $('.js-scroll').each(function () {
    var thisPos = $(this).offset().top;

    if (thisPos < effectPos) {
      // .js-scrollという要素が可視範囲に入ったら
      $.when( // .js-scrollにshowというclassを付与
      $(this).addClass("show")).done(function () {
        //  その後、0.5秒遅らせて.js-scrollにdoneというclassを付与
        $(this).delay(500).queue(function () {
          $(this).addClass("done");
        });
      });
    }
  });
}); //ハンバーガメニュー

(function ($) {
  $(function () {
    var $header = $('#header'); // Nav Toggle Button

    $('#nav_toggle').click(function () {
      $header.toggleClass('open');
    });
    $('nav a').on('click', function () {
      if (window.innerWidth <= 768) {
        $header.removeClass('open');
      }
    });
  });
})(jQuery); //ページ内リンクスムーススクロール


$(function () {
  var headerHight = 72; //ヘッダの高さ
  // #で始まるアンカーをクリックした場合に処理

  $('a[href^="#"]').click(function () {
    // スクロールの速度
    var speed = 800; // ミリ秒
    // アンカーの値取得

    var href = $(this).attr("href"); // 移動先を取得

    var target = $(href == "#" || href == "" ? 'html' : href); // 移動先を数値で取得

    var position = target.offset().top - headerHight; //ヘッダの高さ分位置をずらす
    // スムーススクロール

    $('body,html').animate({
      scrollTop: position
    }, speed, 'swing');
    return false;
  });
}); // page Topフェードイン・アウト

$(function () {
  var topBtn = $('#page-top');
  topBtn.hide(); //スクロールが100に達したらボタン表示

  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  }); //スクロールしてトップ

  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
    return false;
  });
});

},{}]},{},[1]);
